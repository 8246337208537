import React from "react"
import Security from "../components/body/pages/protection"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ProtectionPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/security/"}
      title="Security | How The Money App for Africans Protects You | Kuda"
      description="Kuda, the money app for Africans, protects your identity, account & money using biometrics, secure PINs, 3DS card payments, transaction alerts & more."
    />
    <Security />
  </Layout>
)

export default ProtectionPage
