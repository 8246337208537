import React, { useEffect, Fragment, useState } from "react"
import CTA from "../general/cta"
import Insurance from "../../../../assets/img/insurance.inline.svg"
import Personal from "../../../../assets/img/personal.inline.svg"
import Secure from "../../../../assets/img/secure.inline.svg"
import Pin from "../../../../assets/img/pin.inline.svg"
import Alerts from "../../../../assets/img/alerts.inline.svg"
import Control from "../../../../assets/img/control.inline.svg"
import Beneficiaries from "../../../../assets/img/beneficiaries.inline.svg"
import Biometrics from "../../../../assets/img/biometrics.inline.svg"
import Password from "../../../../assets/img/password.inline.svg"
import Facial from "../../../../assets/img/facial.inline.svg"
import Document from "../../../../assets/img/document.inline.svg"
import ProtectIcon from "../../../../assets/img/protect.inline.svg"
import { scrollToElement } from "../../../utility/utils"
import Panel from "./component/panel"

const protection = [
  {
    title: "Your Identity Documents",
    subtitle:
      "We ask everyone who opens a Kuda account to give us their BVN (Bank Verification Number) and a valid ID issued by the government (driver’s license, voter’s card, national ID card or NIN slip). This helps us prevent identity fraud by confirming that everyone who uses Kuda, including you, is who they say they are.",
    image: <Document />,
  },
  {
    title: "Your Facial Identity",
    subtitle:
      "It’s mandatory for anyone opening a Kuda account to take a photo of their face during the account opening process so that we can confirm they’re opening the account themselves. This also helps prevent identity fraud.",
    image: <Facial />,
  },
  {
    title: "Your Password",
    subtitle:
      "When you’re opening your Kuda account, we ask that you create a password (with any combination of letters, numbers and special characters) to secure your account. We may ask for your password to authorise any changes you make to your account information.",
    image: <Password />,
  },
  {
    title: "Your PINs",
    subtitle:
      "Every Kuda account is secured with a unique six-digit PIN that only the account holder should have access to. You can’t use your Kuda account without first setting your sign-in PIN. Your transactions on the app are also secured with a transaction PIN, and your Kuda Card has its own four-digit PIN.",
    image: <Pin />,
  },
  {
    title: "Biometrics",
    subtitle:
      "The Kuda app is enabled for biometrics (fingerprint and Face ID options) to add an extra layer of security when you’re signing in to the app or authorising transactions, if your device has a fingerprint scanner or Face ID.",
    image: <Biometrics />,
  },
  {
    title: "Confirming Your Unsaved Beneficiaries",
    subtitle:
      "When you’re sending money to a beneficiary you haven’t saved, we ask you to confirm that you’re sure you want to make the transfer. We do this to give you some time to double check that you’re not being scammed - completed transfers are almost impossible to reverse.",
    image: <Beneficiaries />,
  },
  {
    title: "Transaction Alerts",
    subtitle:
      "We send you real time alerts (in the form of app notifications and emails) for transactions on your account. This keeps you up to date on what’s happening with your money.",
    image: <Alerts />,
  },
  {
    title: "Card Control",
    subtitle:
      "On the Kuda app, you can choose where your Kuda Card works - POS, online and/or ATM. If your card is missing or you suspect that it has been stolen, you can block or cancel your card on the app so that no one can use it.",
    image: <Control />,
  },
  {
    title: "3D Secure (3DS) Card Payments",
    subtitle:
      "3D Secure is an extra layer of security for card payments you make online. When paying with your card online, we’ll send you a code by SMS and/or email to authorise the payment.",
    image: <Secure />,
  },
  {
    title: "Your Personal Information",
    subtitle:
      "We use the highest level of encryption to protect your personal information including your identity and confidential account details. We don’t share your information with unauthorised third parties, we would never sell your information and we don’t use your BVN to access any other bank account(s) you may have.",
    image: <Personal />,
  },
  {
    title: "Insuring Your Money",
    subtitle: (
      <span className="questions-head">
        {" "}
        We insure all deposits you make into your Kuda Microfinance Bank account
        with the National Deposit Insurance Commission (NDIC).{" "}
        <p>
          {" "}
          If you make an insurance claim on a deposit and the claim is
          successful, the Deposit Insurance Fund of the NDIC is expected to pay
          you up to 200,000 naira in compensation.
        </p>
      </span>
    ),
    image: <Insurance />,
  },
]

const questions = [
  {
    q: (
      <span className="question--text">
        {" "}
        I’m not sure I made a card transaction I was charged for.
      </span>
    ),
    a: (
      <span className="questions-head">
        <p>
          {" "}
          If you’re not sure you made a card transaction you were charged for,
          please{" "}
          <a
            href="https://app.kuda.com/"
            className="color-primary text-bold link-decoration"
          >
            block your card on the app
          </a>{" "}
          immediately.{" "}
        </p>
        <p>
          {" "}
          Before you reach out to us about the transaction, please take these
          steps:{" "}
        </p>
        <p>
          {" "}
          1. Confirm that the merchant or vendor’s name isn’t actually correct.
          Some merchants use a different name on their POS terminals or online
          payment gateways. Search for the name on the transaction online to be
          sure.{" "}
        </p>{" "}
        <p>
          {" "}
          2. Confirm that you haven’t subscribed to any free trial that has
          expired and become a paid subscription.{" "}
        </p>{" "}
        <p>
          {" "}
          3. Confirm that you don’t have any recurring online payments that
          you’re charged for automatically. You may have added your card to one
          or more apps that charge you at regular intervals eg. Netflix, Spotify
          or Apple Music.{" "}
        </p>{" "}
        <p>
          {" "}
          If you’re still not sure about the card transaction, please call{" "}
          <a
            href="tel:0700022555832"
            className="color-primary text-bold link-decoration phone_number"
          >
            0700022555832
          </a>{" "}
          or send an email to{" "}
          <a
            href="mailto:fraud@kuda.com"
            target="_blank"
            rel="noopener noreferrer"
            className="color-primary text-bold link-decoration"
          >
            fraud@kuda.com{" "}
          </a>{" "}
          or{" "}
          <a
            href="mailto:help@kuda.com"
            target="_blank"
            rel="noopener noreferrer"
            className="color-primary text-bold link-decoration"
          >
            help@kuda.com
          </a>
        </p>
      </span>
    ),
  },
  {
    q: (
      <span className="question--text">
        {" "}
        My card is missing or has been stolen.
      </span>
    ),
    a: (
      <span className="questions-head">
        <p>
          {" "}
          If your Kuda Card is missing or you suspect it has been stolen, please{" "}
          <a
            href="https://app.kuda.com/"
            className="color-primary text-bold link-decoration"
          >
            {" "}
            block it{" "}
          </a>{" "}
          on the app immediately.{" "}
        </p>
        <p>
          {" "}
          You can unblock your card if you find it, or you can cancel it and
          request a replacement card on the app.{" "}
        </p>
        <p>
          {" "}
          If you can’t access the app to block your card, please call{" "}
          <a
            href="tel:0700022555832"
            className="color-primary text-bold link-decoration  phone_number"
          >
            0700022555832
          </a>{" "}
          or send an email to{" "}
          <a
            href="mailto:help@kuda.com"
            target="_blank"
            rel="noopener noreferrer"
            className="color-primary text-bold link-decoration"
          >
            help@kuda.com
          </a>{" "}
          immediately.{" "}
        </p>
      </span>
    ),
  },
  {
    q: (
      <span className="question--text">
        {" "}
        I’m being asked to pay some money quickly. What should I do?
      </span>
    ),
    a: (
      <span className="questions-head">
        <p>
          {" "}
          Scammers often try to create panic and pressure their victims into
          making a payment without thinking carefully first.{" "}
        </p>
        <p>
          {" "}
          If you’re not sure of the person or organisation asking you to pay or
          you suspect that they could be a scammer, please don’t make the
          payment.{" "}
        </p>
        <p>
          {" "}
          Instead, send the account details of the person or organisation to{" "}
          <a
            href="mailto:fraud@kuda.com"
            target="_blank"
            rel="noopener noreferrer"
            className="color-primary text-bold link-decoration"
          >
            fraud@kuda.com{" "}
          </a>{" "}
          and we’ll look into them for you.{" "}
        </p>
      </span>
    ),
  },
  {
    q: (
      <span className="question--text">
        {" "}
        How can I spot a payment scam before it happens?
      </span>
    ),
    a: (
      <span className="questions-head">
        <p>
          {" "}
          Payment scams take several forms. Some of the most common ones
          include:{" "}
        </p>{" "}
        <p>
          {" "}
          1. A text message or an email asking you to click or tap a link to
          update your account information.{" "}
        </p>{" "}
        <p>
          {" "}
          2. A text message, an email or a phone call asking you to pay to get a
          Kuda Overdraft.{" "}
        </p>{" "}
        <p>
          {" "}
          3. A text message, an email or a phone call asking you to share
          confidential account details like your password, PIN or Pay ID.{" "}
        </p>{" "}
        <p>
          {" "}
          4. An unknown or unverified merchant or vendor asking you to pay
          before receiving your order.
        </p>{" "}
        <p>
          {" "}
          Generally, any text message, email or phone call about your Kuda
          account that you cannot confirm is from us is definitely dangerous and
          must be ignored.
        </p>
        <p>
          {" "}
          If you are in doubt about any text message, email or phone call,
          please call{" "}
          <a
            href="tel:0700022555832"
            className="color-primary text-bold link-decoration"
          >
            0700022555832
          </a>{" "}
          or send an email to{" "}
          <a
            href="mailto:fraud@kuda.com"
            target="_blank"
            rel="noopener noreferrer"
            className="color-primary text-bold link-decoration"
          >
            fraud@kuda.com{" "}
          </a>{" "}
          or{" "}
          <a
            href="mailto:help@kuda.com"
            target="_blank"
            rel="noopener noreferrer"
            className="color-primary text-bold link-decoration"
          >
            help@kuda.com
          </a>{" "}
          before taking any other action.{" "}
        </p>
      </span>
    ),
  },
  {
    q: (
      <span className="question--text"> How do I report a suspected scam?</span>
    ),
    a: (
      <span className="questions-head">
        <p>
          {" "}
          If you’re not sure about any transaction on your account or you
          suspect you have been scammed, please call{" "}
          <a
            href="tel:0700022555832"
            className="color-primary text-bold link-decoration  phone_number"
          >
            0700022555832
          </a>{" "}
          or send an email to{" "}
          <a
            href="mailto:fraud@kuda.com"
            target="_blank"
            rel="noopener noreferrer"
            className="color-primary text-bold link-decoration"
          >
            fraud@kuda.com{" "}
          </a>{" "}
          or{" "}
          <a
            href="mailto:help@kuda.com"
            target="_blank"
            rel="noopener noreferrer"
            className="color-primary text-bold link-decoration"
          >
            help@kuda.com
          </a>{" "}
          immediately.{" "}
        </p>
        <p>
          {" "}
          Please, don’t post details of suspected scams or suspicious
          transactions on social media because we will not be able to respond to
          you there for security reasons. Instead of posting on social media,
          please reach out to us directly by phone or email, or chat with us on
          the app.{" "}
        </p>
        <p>
          {" "}
          Please, note that when you report a suspicious transaction or a
          suspected scam, we’ll lock your account temporarily to protect your
          money while we investigate your report.{" "}
        </p>
      </span>
    ),
  },
]

const Security = () => {
  const [state, setState] = useState({
    activeTab: 0,
  })

  const activateTab = index => {
    setState(prevState => ({
      ...prevState,
      activeTab: prevState.activeTab === index ? -1 : index,
    }))
  }

  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  const { activeTab } = state

  return (
    <Fragment>
      <div className="kuda-inner--heading kuda-disclaimer--section">
        <div className="kuda-section--inner">
          <div className="kuda-section--100 text-center">
            <div className="block-item--icon">
              <ProtectIcon />
            </div>
            <h1 className="kuda-section--heading text-xl text-xlbold color-primary kuda--heading">
              How Kuda Protects You
            </h1>
          </div>
          <div className="kuda-section--100 kuda-spacing--inner">
            <div className="kuda-protection--cards flex justify-between flex-wrap">
              {protection.map((item, i) => (
                <div className="kuda-single--protection" key={i}>
                  <div className="kuda-protection--wrap">
                    <div className="kuda-protection--info flex justify-between align-center">
                      {item.image}
                    </div>
                    <div className="kuda-protection--details">
                      <h2 className="text-xlbold color-primary title-bottom--spacing kuda-protection--heading">
                        {item.title}
                      </h2>
                      <p className="color-black kuda-protection--subheading">
                        {item.subtitle}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="kuda-section--100 kuda-spacing--inner kuda-standard--section">
            <h1 className="kuda-section--heading kuda-protect text-center text-xl text-xlbold color-primary title-bottom--spacing">
              Frequently Asked Questions{" "}
            </h1>
            <div className="accordion mt-5" role="tablist">
              {questions.map((panel, index) => (
                <Panel
                  key={index}
                  activeTab={activeTab}
                  index={index}
                  {...panel}
                  activateTab={() => activateTab(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <CTA />
    </Fragment>
  )
}

export default Security
